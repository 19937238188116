<template>
  <div
    class="flex justify-center gap-2.5"
    :class="isCentered ? 'sm:flex-col flex-row-reverse sm:items-stretch items-center' : 'flex-col'"
  >
    <div class="flex justify-between">
      <slot name="before-field" />
      <slot name="switch">
        <ui-fields-switch
          v-if="name"
          :label-true="isMobile ? '' : labelTrue"
          :label-false="isMobile ? '' : labelFalse"
          :checked-value="checkedValue"
          :unchecked-value="uncheckedValue"
          :name="name"
          class="ml-auto self-start"
        />
      </slot>
    </div>
    <slot />
  </div>
</template>
<script setup lang="ts">
  type Props = {
    name?: string;
    labelTrue?: string;
    labelFalse?: string;
    checkedValue?: string | number | boolean;
    uncheckedValue?: string | number | boolean;
    isCentered?: boolean;
  };
  withDefaults(defineProps<Props>(), {
    labelTrue: 'show',
    labelFalse: 'hide',
    checkedValue: 1,
    uncheckedValue: 0,
    isCentered: true
  });
  const isMobile = useIsLessThan('sm');
</script>
