<template>
  <div class="flex h-full w-full px-2.5 lg:px-6 lg:pt-14 pt-2.5 lg:bg-card bg-body relative overflow-y-auto">
    <div class="w-full max-w-xl mx-auto">
      <div class="grid gap-2.5 lg:gap-7 rounded-lg lg:p-0 p-2.5 bg-card">
        <div class="text-center">
          <ui-typography
            tag="h1"
            variant="h2"
            text="Welcome!"
          />
          <div>
            <ui-typography
              variant="body2"
              text="Thank you for the registration!"
            />
            <ui-typography
              variant="body2"
              text="Customize your profile to find the best connections."
            />
          </div>
        </div>
        <common-forms-profile-edit
          is-profile
          class="lg:pb-0 pb-20"
          @success="goToDashboard"
        >
          <template #before>
            <!-- avatar -->
            <common-avatar-update class="justify-self-center" />
          </template>
          <template #actions="{ isSubmitting }">
            <div
              class="lg:sticky lg:pb-14 fixed z-50 bottom-0 right-0 w-full bg-gradient-to-t from-card to-transparent pb-5"
            >
              <div class="mx-auto max-w-xl lg:px-0 sm:px-2.5 px-5">
                <div class="flex gap-2 lg:gap-3 justify-center">
                  <ui-button
                    full
                    text="Skip"
                    variant="secondary-outline"
                    :to="{ name: 'dashboard-profile' }"
                  />

                  <ui-button
                    text="Save"
                    type="submit"
                    full
                    class="max-w-md"
                    :loading="isSubmitting"
                  />
                </div>
              </div>
            </div>
          </template>
        </common-forms-profile-edit>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'auth',
    hideSidebarMobile: true
  });
  const { routerPush } = useRouterLocale();
  const goToDashboard = () => {
    routerPush({ name: 'dashboard-profile' });
  };

  useSeoMeta({
    title: 'Setup Profile'
  });
</script>
