<template>
  <ui-loader
    :loading="pending"
    :error="error"
    static
  >
    <form
      class="grid grid-cols-1 lg:gap-7 gap-5"
      v-bind="$attrs"
      @submit="onSubmit"
    >
      <div class="grid grid-cols-1 gap-2.5 sm:gap-5">
        <slot name="before" />
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2.5 sm:gap-5 lg:gap-7">
          <ui-fields-text
            name="first_name"
            label="First Name"
            placeholder="Enter Your First Name"
            placeholder-show
          />
          <ui-fields-text
            name="last_name"
            label="Last Name"
            placeholder="Enter Your First Name"
          />
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2.5 sm:gap-5 lg:gap-7">
          <ui-fields-text
            name="username"
            label="Nickname"
            placeholder="Enter Your Nickname"
          />
          <ui-fields-text
            name="email"
            is-required
            type="email"
            label="Email"
            placeholder="Enter Your Email"
          />
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2.5 sm:gap-5 lg:gap-7">
          <ui-parts-field-with-switch name="profile.is_visible_dob">
            <ui-fields-calendar
              name="profile.dob"
              label="Date of Birth"
              placeholder="Enter Your Date of Birth"
              filed-wrap-class="flex-1"
              :max-date="currentDate"
            />
          </ui-parts-field-with-switch>
          <ui-parts-field-with-switch name="profile.is_visible_location">
            <ui-fields-text
              name="profile.location"
              label="Location"
              placeholder="Enter Your Location"
              filed-wrap-class="flex-1"
            />
          </ui-parts-field-with-switch>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2.5 sm:gap-5 lg:gap-7">
          <ui-parts-field-with-switch name="profile.is_visible_gender">
            <ui-fields-select
              label="Gender"
              :options="genderOptions"
              name="profile.gender"
              filed-wrap-class="flex-1"
            />
          </ui-parts-field-with-switch>
          <common-forms-parts-edit-mood v-if="user?.subscription_plan.limitations.custom_mood" />
          <ui-parts-mood-select v-else />
        </div>
      </div>
      <template v-if="user">
        <common-forms-parts-interests-list
          v-if="isProfile"
          :data="interestsStore.profilePrivateInterests"
          :source="user"
        />
        <common-profile-interests-list
          v-else
          :data="interestsStore.profilePrivateInterests"
          :user="user"
        >
          <div class="flex items-center gap-2.5">
            <ui-typography
              tag="h3"
              variant="h3"
              text="Interests"
              class="sm:text-start text-center"
            />
            <ui-button
              icon-left="edit"
              size="xs-icon"
              rounded="sm"
              variant="secondary"
              @click="openUpdateInterests"
            />
          </div>
        </common-profile-interests-list>
      </template>
      <div>
        <client-only>
          <ui-parts-field-with-switch
            name="profile.is_visible_about"
            :is-centered="false"
          >
            <template #before-field>
              <ui-typography
                tag="h3"
                variant="h3"
                text="Write Your Story"
                class="sm:text-start text-center"
              />
            </template>
            <ui-fields-textarea
              name="profile.about"
              label="My Story"
              filed-wrap-class="flex-1"
            />
          </ui-parts-field-with-switch>
        </client-only>
      </div>

      <slot
        name="actions"
        :is-submitting="isSubmitting"
      >
        <div class="flex">
          <ui-button
            text="Update"
            type="submit"
            full
            class="max-w-md mx-auto"
            :loading="isSubmitting"
          />
        </div>
      </slot>
    </form>
  </ui-loader>
</template>
<script setup lang="ts">
  import { object } from 'yup';

  type Props = {
    isProfile?: boolean;
  };
  defineProps<Props>();

  const apiRoutes = useApiRoutes();
  const emit = defineEmits(['success']);
  const interestsStore = useInterestsStore();
  const { user, onUpdateUser } = useAuthUser();
  const genderOptions = Object.values(GenderSelect);
  const currentDate = new Date();
  const { pending, error } = await useLazyAsyncData('private-user-interests', async () => {
    await Promise.all([interestsStore.fetchDefaultInterests(), interestsStore.fetchProfileInterests()]);
    return true;
  });
  const initialValues = computed(() => {
    const defaultValues = {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      mood_id: null,
      profile: {
        dob: '',
        location: '',
        gender: null,
        about: '',
        is_visible_dob: 1,
        is_visible_location: 1,
        is_visible_gender: 1,
        is_visible_about: 1
      },
      interest_ids: []
    };
    const form = getCleanFormValue(user.value as User, defaultValues, true);

    return {
      ...form,
      mood_id: user.value?.mood?.id || null,
      profile: {
        ...form.profile,
        is_visible_dob: +form.profile.is_visible_dob,
        is_visible_location: +form.profile.is_visible_location,
        is_visible_gender: +form.profile.is_visible_gender,
        is_visible_about: +form.profile.is_visible_about
      }
    };
  });

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting, setFieldValue } = useForm({
    validationSchema: object({
      email: validationFields.validationEmail()
    }),
    initialValues
  });

  const { open: openUpdateInterests } = useUpdateInterestsModal({
    interestsIds: computed(() => initialValues.value.interest_ids),
    source: user,
    onSuccess: (data: User) => {
      setFieldValue('interest_ids', data.interest_ids);
      onUpdateUser(data);
    }
  });

  const prepareSubmit = (data: any) => {
    if (data.mood_id) {
      delete data.custom_mood;
    }
    return data;
  };

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        return apiRoutes.profile.update(prepareSubmit(form));
      },
      initialValues,
      {
        async onSuccess(data) {
          onUpdateUser(data);
          emit('success');
        },
        isCleanData: true,
        successToast: true
      }
    )
  );
</script>
