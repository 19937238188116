<template>
  <ui-parts-field-with-switch :is-centered="false">
    <template #switch>
      <ui-fields-switch
        v-model="customMood"
        :checked-value="true"
        :unchecked-value="false"
        class="ml-auto"
        label-false="Custom Mood"
        enabled-controlled
        sync-v-model
      />
    </template>
    <button
      v-if="customMood"
      type="button"
      class="hover:opacity-85 focus:opacity-75 flex items-center gap-2 w-full bg-primary text-white md:text-lg rounded-lg px-6 md:py-4 py-2.5 border justify-between my-1.5"
      :class="editCustomMood ? 'border-secondary' : 'border-primary'"
      :style="{
        backgroundColor: editCustomMood ? currentMood.color : undefined,
        borderColor: editCustomMood ? currentMood.color : undefined
      }"
      @click="openCustomMoodModal"
    >
      <ui-icon
        v-if="editCustomMood"
        class="shrink-0"
        :name="currentMood.icon"
      />
      <span class="truncate capitalize">{{ editCustomMood ? currentMood.text : $t('Add') }}</span>
      <ui-icon
        :name="editCustomMood ? 'edit' : 'add'"
        class="shrink-0 ml-auto"
      />
    </button>
    <ui-parts-mood-select
      v-else
      class="w-full"
    />
  </ui-parts-field-with-switch>
</template>

<script setup lang="ts">
  import { useSetFieldValue } from 'vee-validate';

  const { user } = useAuthUser();
  const customMood = ref(false);
  const { moods } = await useGetMoods();
  const { open } = useModalCustomMoodModal({});
  const { value: customMoodValue } = useField('custom_mood', undefined);
  const currentMood = computed<any>(() => customMoodValue.value || user.value?.mood);
  const selectCustomMood = computed(() => !useSome(moods.value, { id: currentMood.value?.id }));
  const editCustomMood = computed(() => currentMood.value && selectCustomMood.value);

  const setMoodId = useSetFieldValue('mood_id');

  const openCustomMoodModal = () => {
    open({
      initialValues: selectCustomMood.value ? currentMood.value : undefined,
      onSuccess: (val: Omit<Mood, 'id'>) => {
        customMoodValue.value = val;
      }
    });
  };

  watch(
    moods,
    () => {
      if (moods.value.length) {
        customMood.value = !!selectCustomMood.value;
        if (customMood.value) {
          setMoodId(undefined);
        }
      }
    },
    {
      immediate: true
    }
  );
</script>
